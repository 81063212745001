.badge-list-wrapper {
  position: relative;
  width: 100%;
}

.badge-list {
  width: 730px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.badge {
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  flex-shrink: 0;

  &:hover {
    color: #908dd6;
  }

  &:not(:last-child) {
    margin-right: $spacer-4;
  }
}

.icon {
  position: relative;
  cursor: pointer;
  fill: #f2f2f2;

  &:hover {
    border-radius: $spacer-3;
  }

  &-ghost-right {
    position: absolute;
    background: linear-gradient(to left, rgba(6, 7, 8, 0.9) 20%, rgba(95, 84, 84, 0) 80%);
    width: 30px;
    right: 30px;
    height: 32px;
  }

  &-ghost-left {
    position: absolute;
    background: linear-gradient(to right, rgba(6, 7, 8, 0.9) 20%, rgba(255, 255, 255, 0) 80%);
    width: 30px;
    left: 30px;
    top: 0;
    height: 32px;
  }
}

.scroll-badge-content {
  display: flex;
  align-items: center;
  list-style: none;
  max-width: 100%;
  overflow-x: scroll;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    background: transparent;
    /* make scrollbar transparent */
    -webkit-appearance: none;
    width: 0;
    height: 0;
  }
}

@media (max-width: 900px) {
  .badge-list {
    width: 500px;
  }
}

@media (max-width: 580px) {
  .badge-list {
    width: 270px;
  }
}

@media (max-width: 280px) {
  .badge-list {
    width: 150px;
  }
}

.scroll-button {
  position: absolute;
  z-index: 2;

  &.scroll-button-start {
    left: -5px;

    > .icon-ghost-left {
      left: 0;
      width: 81px;
    }

    > svg {
      z-index: 1;
      position: relative;
    }
  }

  &.scroll-button-end {
    right: -5px;
    z-index: 2;

    > .icon-ghost-right {
      right: 0;
      width: 81px;
    }

    > svg {
      position: relative;
    }
  }
}
