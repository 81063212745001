// base colors

$pink-1: #f368e0;
$pink-2: #ff9ff3;
$red-1: #ff6b6b;
$red-2: #ee5253;
$velvet-1: #00d2d3;
$velvet-2: #01a3a4;
$cyan-1: #48dbfb;
$cyan-2: #0abde3;
$blue-1: #54a0ff;
$blue-2: #2e86de;
$yellow-1: #feca57;
$yellow-2: #ff9f43;
$green-1: #1dd1a1;
$green-2: #10ac84;
$purple-1: #5f27cd;
$purple-2: #341f97;
$gray-1: #c8d6e5;
$gray-2: #c8d6e5;
$gray-3: #8395a7;
$gray-4: #576574;
$gray-5: #222f3e;
$white: #fff;

//border-radius
$spacer-1: 2px;
$spacer-2: 4px;
$spacer-3: 8px;
$spacer-4: 16px;
$spacer-5: 32px;
$spacer-6: 64px;