.badge {
  height: 20px;
  border-radius: $spacer-3;
  border: 1px solid $gray-4;
  text-align: center;
  display: inline-flex;
  color: $white;
  min-width: 16px;
  padding: $spacer-2 $spacer-3;
}

@media (max-width: 280px) {
  .badge {
    height: 16px;
    font-size: 12px;
  }
}