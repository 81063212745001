.card {
  width: 300px;
  height: 250px;
  background: linear-gradient(rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.05) 100%);
  padding: 24px;
  border-radius: $spacer-5;
  transition: 0.3s;
  position: relative;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  cursor: pointer;

  .header {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .logo {
      img {
        width: 60px;
        height: 60px;
        display: block;
        border-radius: 16px;
        background-color: $gray-5;
      }
    }

    > .right {
      display: flex;
      flex-direction: column;
      margin-left: 10px;

      .name {
        font-size: 22px;
        font-weight: bold;
        color: $gray-3;
      }

      .suggester {
        display: flex;
        align-items: center;
        margin-top: 5px;
        color: #c8d6e5;
        font-size: 12px;
        gap: 5px;

        > p {
          max-width: 15ch;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        > img {
          width: 25px;
          border-radius: 100%;
          border: 1px solid #1b1d2b;
        }
      }
    }
  }

  .body {
    color: $gray-1;
    max-height: fit-content;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    word-break: break-word;
    line-height: 22px;
    user-select: none;
    overflow-y: auto;
    margin: 20px 0;
  }

  .categories {
    display: flex;
    flex-wrap: wrap;
    max-height: 67px;
    overflow-y: scroll;
    margin-top: auto;

    > * {
      margin-right: $spacer-2;
      margin-bottom: $spacer-2;
    }

    .badge {
      &:hover {
        color: $white;
      }
    }
  }

  .badge-list {
    display: flex;
    width: 100%;
    justify-content: flex-start;

    .icon {
      &-ghost-right {
        background: linear-gradient(to left, rgba(10, 11, 27, 0.89) 20%, rgba(95, 84, 84, 0) 80%);
      }

      &-ghost-left {
        background: linear-gradient(to right, rgba(10, 11, 27, 0.89) 20%, rgba(255, 255, 255, 0) 80%);
      }
    }
  }

  .badge-list-wrapper {
    display: flex;
    width: 100%;
    justify-content: flex-start;
  }

  &::before {
    content: "";
    pointer-events: none;
    user-select: none;
    position: absolute;
    inset: 0px;
    border-radius: inherit;
    padding: 1px;
    background: linear-gradient(rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.07));
    -webkit-mask: linear-gradient(black, black) content-box content-box, linear-gradient(black, black);
    -webkit-mask-composite: xor;
  }

  &:hover {
    &::before {
      background: linear-gradient(rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2));
      transition: 0.4s ease;
    }
  }

  ::-webkit-scrollbar {
    display: none;
  }
}

@media (max-width: 500px) {
  .card {
    width: 200px;
    max-width: 250px;

    .header {
      .logo {
        img {
          width: 40px;
          height: 40px;
        }
      }

      .name {
        font-size: 18px;
      }
    }
  }
}

@media (max-width: 280px) {
  .card {
    width: 100px;
    height: 150px;

    .header {
      .logo {
        img {
          width: 32px;
          height: 32px;
        }
      }

      .name {
        font-size: 12px;
      }
    }
  }
}
