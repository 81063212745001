$delay: .5s;
$timing: 3s;

.sidebar {
  height: 100vh;
  left: 0;
  position: fixed;
  transition: .2s ease;
  transition-property: transform, width;
  transition-timing-function: ease;
  background: rgba(83, 83, 83, 0.25);
  backdrop-filter: blur(10px);
  color: white;

  .item-wrapper {
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: space-between;
  }

  .download {
    display: flex;
    align-items: center;
  }

  .download {
    display: flex;
    align-items: center;
    cursor: pointer;

    .logo {
      fill: #f2f2f2;
    }

    .content {
      padding-left: 14px;
    }

    &:hover {
      background: rgba(23, 23, 23, 0.15);
    }
  }

  .item {
    padding: 20px;
  }

  &.open {
    width: 320px;
  }

  .sidebar-toggle {
    cursor: pointer;
  }
}

.bar {
  padding: 0;
  position: fixed;
  width: 1px;
  height: 120px;
  box-shadow: 1px 1px 2px 1px rgb(92 103 199);
  border-radius: 50%;
  transition: all $timing linear;
  -webkit-animation-duration: $timing;
  animation-duration: $timing;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}

.bar.delay {
  animation-delay: $delay;
  -webkit-animation-delay: $delay;
}

.right {
  top: 0;
  right: -1px;
}

@-webkit-keyframes v-move {
  0% {
    top: -100px;
  }

  100% {
    top: 100vh;
  }
}

@keyframes v-move {
  0% {
    top: -100px;
  }

  100% {
    top: 100vh;

  }
}

.right {
  -webkit-animation-name: v-move;
  animation-name: v-move;
}

@media (max-width: 500px) {
  .sidebar-wrapper {
    .sidebar {
      background: #101425;
      height: 100vh;
      top: 0;
      left: 0;
      position: fixed;
      z-index: 10;

      &.close {
        width: 75px;
      }

      &.open {
        width: 80vw;
      }

      .sidebar-toggle {
        cursor: pointer;
      }
    }

    .backdrop {
      width: 20%;
      background-color: rgba(255, 255, 255, 0.1);
      height: 100vh;
      z-index: 10;
      position: fixed;
      top: 0;
      right: 0;
    }
  }
}

@media (max-width: 280px) {
  .sidebar-wrapper {
    .sidebar {
      background: rgb(31 32 36);
      height: 100vh;
      top: 0;
      left: 0;
      position: fixed;
      z-index: 10;

      &.close {
        width: 75px;
      }

      &.open {
        width: 80vw;
      }

      .sidebar-toggle {
        cursor: pointer;
      }
    }

    .backdrop {
      width: 20%;
      background-color: rgba(255, 255, 255, 0.1);
      ;
      height: 100vh;
      z-index: 10;
      position: fixed;
      top: 0;
      right: 0;
    }
  }
}


.stop-scrolling {
  height: 50%;
  overflow: hidden;
}