.input {
  display: flex;
  justify-content: space-between;
  padding: $spacer-2;
  left: 0;
  border-radius: $spacer-3;
  position: relative;
  background-color: transparent;
  position: relative;
  flex-direction: column;

  &.open,
  &.sidebar-expand {
    width: 230px;
    height: 300px;
    background-color: rgb(40, 44, 52);
    box-shadow: 0px 0px 0px 0.2px $white;
    transition: .2s ease;
    margin-left: 20px;
    line-height: 24px;

    .copy {
      flex-shrink: 0;
      justify-content: center;
    }

    .copy {
      background: rgba(128, 128, 128, 0.25);
    }
  }

  &.close {
    height: 32px;
    transition: none;
    background-color: unset;
    cursor: pointer;
    border: unset;

    &.disabled {
      cursor: not-allowed;
    }

    &:hover {
      background: rgba(23, 23, 23, 0.15);
      transition: none;
      border-radius: 0;

      .copy {
        transition: none;
        cursor: pointer;

        &.disabled {
          transition: none;
          cursor: not-allowed;
        }

        &.active {
          transition: none;
          cursor: pointer !important;
        }
      }
    }
  }

  .input-content {
    display: block;
    flex-wrap: wrap;
    width: 100%;
    overflow: auto;
    text-overflow: ellipsis;
    font-size: 14px;
    /* Safari */
    -webkit-user-select: none;
    /* Standard syntax */
    user-select: none;
    font-family: monospace;
    color: $white;
    padding-bottom: 20px;
    margin-bottom: 36px;
  }

  .copy {
    position: relative;
    width: 100%;
    height: 40px;
    border-radius: $spacer-2;
    display: flex;
    align-items: center;

    &.background-disabled {
      background-color: none;
    }

    &.active {
      background-color: $green-2;
      cursor: pointer;
      justify-content: space-around;
    }

    &.disabled {
      cursor: not-allowed;
      justify-content: space-around;
      color: $gray-1
    }

    .app-count {
      top: 0;
      right: 0;
      background-color: unset;
      position: absolute;
      color: $white;
      background: green;
      border-radius: 50%;
      padding: $spacer-3;
      height: 1px;
      width: 1px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .tooltip {
    background-color: black;
    color: white;
    position: absolute;
    top: -45px;
    left: -8px;
    padding: $spacer-3;
    border-radius: $spacer-3;
    display: none;
    transition: height 350ms ease-in-out;

    &:before {
      content: '';
      width: 10px;
      height: 10px;
      position: absolute;
      bottom: -4px;
      left: 45%;
      background-color: black;
      border-radius: $spacer-1;
      transform: rotate(45deg);
    }

    &.disabled {
      display: none;
      transition: height 350ms ease-in-out;
    }

    &.active {
      display: block;
      transition: height 350ms ease-in-out;
    }

    &.open {
      left: inherit;
      margin-top: -6px;
    }
  }

  &-checkbox {
    position: absolute;
    bottom: 70px;
    align-items: center;
    color: hsla(0deg, 0%, 100%, 0.4);
    font-size: 14px;
    user-select: none;
    line-height: 16px;
    cursor: pointer;

    input[type=checkbox] {
      accent-color: $gray-4;
    }
  }
}

@media (max-width: 500px) {
  .input {

    &.open,
    &.sidebar-expand {
      height: 260px;
      width: calc(100% - 80px);
    }
  }
}

@media (max-width: 280px) {
  .input {
    .copy {
      font-size: 12px;
    }
  }
}