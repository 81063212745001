.search {
  display: flex;
  border-bottom: 1px solid $white;
  align-items: center;
  background: rgba(83, 83, 83, 0.25);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  border-radius: $spacer-4;
  padding: 8px;
  position: relative;
  justify-content: center;
  width: 300px;
  align-items: center;
  margin-top: 20px;

  input {
    background-color: transparent;
    border: none;
    color: $white;
    outline: none;
    font-size: 20px;
    margin: 0 $spacer-2;
    width: 100%;

    &::placeholder {
      color: $white;
    }
  }

  .search-icon {
    fill: $white;
    width: 32px;
    height: 32px;
    cursor: pointer;
  }

  .close-icon {
    width: 32px;
    height: 32px;
    cursor: pointer;
  }
}

.search-wrapper{
  margin-top: 20px;
}

@media (max-width: 500px) {
  .search {
    width: 200px;
  }
}

@media (max-width: 280px) {
  .search {
    width: 120px;

    input {
      font-size: 14px;
    }
  }
}
