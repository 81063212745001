@import url(https://fonts.googleapis.com/css?family=Roboto:500);

.action-button {
  position: absolute;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  // background: $green-1;
  // background: rgba($green-1, 60%);
  // background: radial-gradient(ellipse 80% 50% at 50% -20%, rgba(120, 119, 198, 0.3), rgba(255, 255, 255, 0));
  // background: linear-gradient(90deg, hsla(252, 40%, 29%, 1) 0%, hsla(270, 77%, 71%, 1) 100%);
  background: linear-gradient(0deg, rgba(5, 1, 13, .6), rgba(5, 1, 13, .6)), linear-gradient(101.54deg, #7cefff 0%, #1b0536 100%);
  // box-shadow: 0px 0px 2px 0px #fff;
  position: absolute;
  top: -10px;
  right: -10px;
  cursor: pointer;

  >span:nth-child(1) {
    display: block;
    position: absolute;
    margin: 0 26%;
    width: 50%;
    height: 5px;
    border-radius: $spacer-1;
    // background: $white;
    top: calc(50% - 3.5px);
    -moz-transition: all .25s ease;
    -webkit-transition: all .25s ease;
    -o-transition: all .25s ease;
    transition: all .25s ease;
    background: rgba($white, 0.5);

  }

  >span:nth-child(2) {
    background: rgba($white, 0.5);
    display: block;
    position: absolute;
    margin: 23% 3%;
    width: 5px;
    height: 50%;
    border-radius: $spacer-1;
    // background: $white;
    left: calc(50% - 3.5px);
    -moz-transition: all .25s ease;
    -webkit-transition: all .25s ease;
    -o-transition: all .25s ease;
    transition: all .25s ease;
  }

  &.minus {
    // background: $red-1;
    // background: rgba($red-1, 60%);
    // background: linear-gradient(90deg, hsla(252, 40%, 29%, 1) 0%, hsla(252, 40%, 29%, 1) 100%);
    // background: rgba(83, 83, 83, 1);
    background: linear-gradient(0deg, rgba(5, 1, 13, .6), rgba(5, 1, 13, .6)), linear-gradient(101.54deg, #fd9eff 12.48%, #9e1f3e 40.34%, #270511 87.81%);

    >span:nth-child(2) {
      background: transparent;
      -moz-transform: rotate(-90deg);
      -webkit-transform: rotate(-90deg);
      -o-transform: rotate(-90deg);
      transform: rotate(-90deg);
    }
  }
}