.terminal {
  width: 800px;
  height: 500px;
  background-color: rgba(81, 71, 79, 17%);
  border-radius: 20px;
  position: relative;
  overflow: hidden;
  margin-top: 100px;

  &-header {
    display: flex;
    align-items: center;
    width: 100%;
    height: 40px;
    position: absolute;
    top: 0;
    background-color: black;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;


    &-action-buttons {
      display: flex;
      margin-left: 24px;

      >div:not(:first-child) {
        margin-left: 8px;
      }

      .red {
        width: 16px;
        height: 16px;
        background-color: #FF5B68;
        border-radius: 50%;
      }

      .yellow {
        width: 16px;
        height: 16px;
        background-color: #FFBE24;
        border-radius: 50%;
      }

      .green {
        width: 16px;
        height: 16px;
        background-color: #1ACC30;
        border-radius: 50%;
      }
    }

    &-window {
      margin-left: 24px;
      background-color: rgba(87, 97, 94, 21%);
      height: 100%;
      display: flex;
      font-size: 14px;
      justify-content: center;
      align-items: center;
      padding: 0 $spacer-4;
    }
  }

  &-body {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 40px;
    padding: $spacer-4;
    color: white;

    &-content {
      display: flex;
      align-items: center;

      .home {
        color: green;
        font-size: 20px;
        font-weight: bold;
        margin-right: 12px;
      }
    }
  }

  &-inner{
    background-color: #1A1824;
    border-radius: inherit;
  }
}

@media (max-width: 1200px) {
  .terminal {
    width: 700px;

    &-header {
      &-action-buttons {

        .red,
        .yellow,
        .green {
          width: 12px;
          height: 12px;
        }
      }

      &-window {
        font-size: 10px;
      }
    }

    &-body-content {
      font-size: 12px;
    }
  }
}

@media (max-width: 1000px) {
  .terminal {
    width: 600px;
  }
}

@media (max-width: 900px) {
  .terminal {
    width: 500px;
  }
}

@media (max-width: 768px) {
  .terminal {
    width: 400px;
  }
}

@media (max-width: 500px) {
  .terminal {
    width: 250px;
    height: 250px;
  }
}

@media (max-width: 280px) {
  .terminal {
    width: 180px;
    height: 200px;

    &-header {
      &-action-buttons {

        .red,
        .yellow,
        .green {
          width: 8px;
          height: 8px;
        }
      }

      &-window {
        font-size: 8px;
      }
    }

    &-body-content {
      font-size: 10px;
    }
  }
}