@import "./assets/styles/variables/index";

.container {
  display: flex;
  justify-content: center;

  .app {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 1400px;
    height: 100%;
    margin-top: 20px;
    transition: .3s ease;

    .search-item-list-wrapper {
      margin-top: $spacer-3;
      min-height: 32px;
      padding: 20px;

      .search-item {
        margin: 4px;
      }
    }

    &.narrow {
      margin-left: 320px;
    }

    &.wide {
      margin-left: 80px;
    }
  }

  &-wrapper {
    background: radial-gradient(ellipse 80% 50% at 50% -20%, rgba(120, 119, 198, 0.3), rgba(255, 255, 255, 0));

    .welcome {
      height: 100%;
    }

    .content-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      height: 100vh;
      margin-top: 20px;
      transition: .3s ease;

      .card-list {
        flex: 1;
        justify-content: center;
        overflow-y: scroll;
        overscroll-behavior: contain;
        margin-top: $spacer-3;
      }
    }
  }

  .badges {
    margin-top: $spacer-4;
  }
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(83, 83, 83, 0.25);
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(83, 83, 83, 0.25);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(109, 108, 108, 0.25);
}

@media (max-width: 1200px) {
  .welcome {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}

@media (max-width: 500px) {
  .container {
    margin-left: 75px;

    .app {
      &.narrow {
        margin-left: unset;
      }

      &.wide {
        margin-left: unset;
      }
    }
  }
}

@media (max-width: 280px) {
  .container {
    margin-left: 70px;

    .app {
      &.narrow {
        margin-left: unset;
      }

      &.wide {
        margin-left: unset;
      }
    }
  }
}