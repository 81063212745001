.welcome {
  height: 100vh;
  color: white;
  position: relative;
  text-align: center;

  .application {
    width: 60px;
    transform: translate3d(0, 0, 0);
    animation-duration: 5s;
    animation-timing-function: ease-in-out;
    animation-delay: 0s;
    animation-iteration-count: infinite;
    animation-direction: normal;
    animation-fill-mode: none;
    animation-play-state: running;
    animation-name: float-translate;
  }

  @for $i from 1 through 12 {
    .app-#{$i} {
      animation-delay: 0.5s * $i;
    }
  }

  .app-wrapper-1,
  .app-wrapper-2,
  .app-wrapper-3,
  .app-wrapper-4 {
    display: flex;
    justify-content: space-evenly;

    >svg {
      padding: 10px 20px;
    }
  }

  .app-wrapper-3 {
    padding: 0 30px;
    margin-bottom: 60px;
  }

  &-header {
    font-size: 80px;
    text-align: center;
    margin-bottom: 36px;
    user-select: none;
  }

  &-description {
    font-size: 36px;
    color: #ABAAAA;
    text-align: center;
    margin-bottom: 36px;
  }

  &-button {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    display: inline-flex;
    flex-shrink: 0;
    color: inherit;
    height: 48px;
    text-shadow: rgba(0, 0, 0, 0.25) 0px 3px 8px;
    gap: 8px;
    margin: 0px;
    border-radius: 24px;
    padding: 0px 24px;
    border-style: none;
    transition: .4s ease;
    background: linear-gradient(92.88deg, rgb(69, 94, 181) 9.16%, rgb(86, 67, 204) 43.89%, rgb(103, 63, 215) 64.72%);

    &:hover {
      text-shadow: rgb(0 0 0 / 56%) 0px 3px 12px;
      box-shadow: rgb(80 63 205 / 50%) 0px 1px 40px;
      transition: .4s ease;
    }
  }
}

@keyframes float-translate {
  0% {
    transform: translate(0px, 0px);
  }

  50% {
    transform: translate(0px, 15px);
  }

  100% {
    transform: translate(0px, 0px);
  }
}

@media (max-width: 1200px) {
  .welcome {
    &-header {
      font-size: 60px;
    }

    &-description {
      font-size: 30px;
    }
  }

  .app-wrapper-1,
  .app-wrapper-2,
  .app-wrapper-3,
  .app-wrapper-4 {
    >svg {
      padding: 14px 14px;
      width: 48px;
      height: 48px;
    }
  }
}

@media (max-width: 1024px) {
  .welcome {
    &-header {
      font-size: 50px;
    }

    &-description {
      font-size: 25px;
    }
  }

  .app-wrapper-1,
  .app-wrapper-2,
  .app-wrapper-3,
  .app-wrapper-4 {
    >svg {
      width: 52px;
      height: 52px;
    }
  }
}

@media (max-width: 900px) {
  .welcome {
    &-header {
      font-size: 45px;
    }

    &-description {
      font-size: 22px;
    }
  }
}

@media (max-width: 768px) {
  .welcome {
    &-header {
      font-size: 40px;
    }

    &-description {
      font-size: 20px;
    }

    .app-wrapper-1,
    .app-wrapper-2,
    .app-wrapper-3,
    .app-wrapper-4 {
      >svg {
        width: 34px;
        height: 34px;
      }
    }
  }
}

@media (max-width: 600px) {
  .welcome {
    &-header {
      font-size: 35px;
    }

    &-description {
      font-size: 18px;
    }
  }
}

@media (max-width: 480px) {
  .welcome {
    &-header {
      font-size: 30px;
    }

    &-description {
      font-size: 16px;
    }

    .app-wrapper-1,
    .app-wrapper-2,
    .app-wrapper-3,
    .app-wrapper-4 {
      >svg {
        padding: 8px;
      }
    }
  }
}

@media (max-width: 320px) {
  .welcome {
    &-header {
      font-size: 25px;
    }

    &-description {
      font-size: 14px;
    }
  }
}

@media (max-width: 280px) {
  .welcome {
    &-header {
      font-size: 20px;
    }

    &-description {
      font-size: 10px;
    }

    .app-wrapper-1,
    .app-wrapper-2,
    .app-wrapper-3,
    .app-wrapper-4 {
      >svg {
        width: 22px;
        padding: 4px;
      }
    }
  }
}